@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
.footer {
  text-align: center;
  font-size: 0.5rem;
  background-color: #fff;
  padding: 10px;
  color: #2c2c2c;
}
