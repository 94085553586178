@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
.header {
  padding: 10px;
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.header .menu {
  color: #2c2c2c;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}

@media only screen and (max-width: 320px) {
  .header .menu {
    top: 8px;
  }
}

.header .menu .menu-trigger,
.header .menu .menu-trigger span {
  display: inline-block;
  -webkit-transition: all .4s;
  transition: all .4s;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.header .menu .menu-trigger {
  position: relative;
  width: 34px;
  height: 28px;
}

@media only screen and (min-width: 961px) {
  .header .menu .menu-trigger {
    display: none;
  }
}

.header .menu .menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #2c2c2c;
  border-radius: 3px;
}

.header .menu .menu-trigger span:nth-of-type(1) {
  top: 0;
}

.header .menu .menu-trigger span:nth-of-type(2) {
  top: 12px;
}

.header .menu .menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.header .menu .menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(14px) rotate(-45deg);
  -ms-transform: translateY(14px) rotate(-45deg);
      transform: translateY(14px) rotate(-45deg);
}

.header .menu .menu-trigger.active span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  -webkit-animation: active-menu-bar02 .8s forwards;
  animation: active-menu-bar02 .8s forwards;
}

@-webkit-keyframes active-menu-bar02 {
  100% {
    height: 0;
  }
}

@keyframes active-menu-bar02 {
  100% {
    height: 0;
  }
}

.header .menu .menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-11px) rotate(45deg);
  -ms-transform: translateY(-11px) rotate(45deg);
      transform: translateY(-11px) rotate(45deg);
}

.header .headerInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 961px) {
  .header .headerInner {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.header .headerInner .logo {
  margin: 0 20px 0 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  line-height: 1;
}

@media only screen and (max-width: 320px) {
  .header .headerInner .logo {
    width: 250px;
  }
}

@media only screen and (min-width: 961px) {
  .header .headerInner .logo a {
    display: inline-block;
  }
}

.header .menuList {
  margin: 0;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  background: #fff;
  top: 0;
  left: 100%;
  -webkit-transition: left 300ms ease-out;
  transition: left 300ms ease-out;
}

.header .menuList.menuList--pc {
  display: none;
}

@media only screen and (min-width: 961px) {
  .header .menuList.menuList--pc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width: 961px) {
  .header .menuList.menuList--sp {
    display: none;
  }
}

@media only screen and (min-width: 961px) {
  .header .menuList {
    position: relative;
    height: auto;
    padding: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.header .menuList.active {
  left: 0;
}

.header .menuList li {
  padding: 5px;
}

@media only screen and (min-width: 961px) {
  .header .menuList li {
    padding: 10px;
  }
}

.header .menuList li:first-child {
  padding-top: 0;
}

@media only screen and (min-width: 961px) {
  .header .menuList li:first-child {
    display: none;
    padding: 0 10px 0 0;
  }
}

.header .menuList li a {
  padding: 5px 0;
  color: #2c2c2c;
  display: block;
  text-decoration: none;
}

@media only screen and (min-width: 961px) {
  .header .menuList li a {
    padding: 0;
  }
}

.header .menuList li a.current {
  background: #2c2c2c;
  color: white;
}

@media only screen and (min-width: 961px) {
  .header .menuList li a.current {
    background-color: #fff;
    color: #2c2c2c;
    border-bottom: 1px solid #2c2c2c;
  }
}
