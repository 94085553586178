@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
ul {
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: darkblue;
}

a:hover {
  text-decoration: underline;
}

body {
  background: url("/images/bg_main.png") 0 0 repeat;
}

#root .breadCrumb {
  max-width: 1200px;
  margin: 20px auto;
  font-size: 0.85rem;
}

#root .breadCrumb p {
  font-size: 0.85rem;
  position: relative;
}

#root .breadCrumb p svg {
  position: absolute;
  top: -1px;
  font-size: 1.4rem;
}

#root .breadCrumb p span {
  text-indent: 1.8em;
}

img {
  max-width: 100%;
}

.loading {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: white;
  z-index: 110;
  opacity: 0;
  -webkit-transition: opacity 600ms ease-in-out;
  transition: opacity 600ms ease-in-out;
}

@media only screen and (min-width: 961px) {
  .mainContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media only screen and (min-width: 961px) {
  .mainContainer .sideBar {
    width: 300px;
    margin: 0 0 0 30px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
}

.mainContainer .mainBody {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}

.content {
  width: 100%;
  padding: 37px 0 50px;
  min-height: 100vh;
}

@media only screen and (min-width: 961px) {
  .content {
    margin: 0 auto;
  }
}

main {
  padding: 20px;
}

.mainInner {
  max-width: 1200px;
  margin: 0 auto;
}

.mainHeading {
  margin: 0 0 50px;
  color: white;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}

@media only screen and (min-width: 961px) {
  .mainHeading {
    padding: 30px;
    font-size: 20px;
  }
}

.mainHeading.onlyEnglish {
  font-family: 'Noto Serif JP', serif;
}

.mainContent {
  padding: 30px;
  background: #ffffff;
  border-top: 2px solid #163D96;
  -webkit-box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.2);
}

.heading02 {
  font-family: "Work Sans", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: black;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.heading02 .MuiSvgIcon-root {
  margin: 0 5px 0 0;
}

.heading03 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.heading03 svg {
  margin: 0 5px 0 0;
  font-size: 1rem;
}

.heading04 {
  border-left: 5px solid #2f2f2f;
  padding-left: 10px;
}

.withIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.withIcon svg {
  margin-right: 2px;
}

.withIconTitle {
  position: relative;
}

.withIconTitle span {
  display: inline-block;
  text-indent: 1.9em;
}

.withIconTitle svg {
  font-size: 47px;
  position: absolute;
  top: -4px;
}

.backButton svg {
  font-size: 1rem;
}

.toTopButton {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 100;
  display: none;
  -webkit-transition: bottom 0.2s ease;
  transition: bottom 0.2s ease;
  cursor: pointer;
}

.toTopButton svg {
  font-size: 3rem;
}

.toTopButton:hover {
  bottom: 50px;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pagination ul {
  margin: 0 auto;
}

.pagination ul .Mui-selected {
  background: black;
  color: white;
}

.shoppingList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media only screen and (max-width: 320px) {
  .shoppingList {
    margin: 0 -10px;
  }
}

/* parts style */
.mainVisual {
  text-align: center;
}

@media only screen and (min-width: 415px) {
  .mainVisual img {
    max-width: 100%;
  }
}

.list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.list li {
  text-align: left;
  margin: 0 0 10px;
}

@media only screen and (min-width: 961px) {
  .list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.list .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 0 5px;
  font-weight: bold;
}

@media only screen and (min-width: 961px) {
  .list .date {
    margin: 0 10px 5px 0;
  }
}

.list .date .tag {
  width: 60px;
  text-align: center;
  padding: 1px 5px;
  font-size: 0.6rem;
  margin: 0 0 0 5px;
}

.tagContainer {
  margin: 0 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.tagContainer + .tagContainer {
  margin: 0 0 10px;
}

.tagContainer .tag {
  margin: 0 10px 10px 0;
  padding: 1px 5px;
  font-size: 0.6rem;
}

.tag {
  background: darkblue;
  color: white;
}

.tag.tour {
  background: darkblue;
  color: white;
}

.tag.info {
  background: lightgrey;
  color: black;
}

.tag.gutereise {
  background: #FBDD63;
  color: white;
}

.tag.goodbyeworld {
  background: #2c2c2c;
  color: white;
}

.tag.sfc_challenge {
  background: #0eb6ee;
  color: white;
}

.tag.trekkingworld {
  background: #035B30;
  color: white;
}

.tag.revolucion {
  background: #E7151A;
  color: white;
}

.tag.midkniteeuro {
  color: white;
  background: #133D97;
}

.tag.midkniteusa {
  color: white;
  background: red;
}

.tag.midkniteasia {
  color: white;
  background: orange;
}

.tag.midknitepacific {
  color: white;
  background: skyblue;
}

.tag.midknitelatin {
  color: white;
  background: darkgreen;
}

.tag.midkniteafrica {
  color: white;
  background: brown;
}

.tag.midknitejapan {
  color: white;
  background: blue;
}

.tag.midkniteblue {
  color: white;
  background: deepskyblue;
}

.tag.tour {
  color: white;
  background: darkblue;
}

.tag.international {
  color: white;
  background: darkblue;
}

.tag.domestic {
  color: white;
  background: red;
}

.textBox {
  max-width: 600px;
  margin: 0 auto;
}

.accordion {
  margin: 20px 0;
}

.checkBox {
  margin: 0 0 20px;
}

.checkBox .formGroup {
  -webkit-box-orient: initial;
  -webkit-box-direction: initial;
      -ms-flex-direction: initial;
          flex-direction: initial;
}

.handleCheckButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.handleCheckButton button {
  margin: 0 10px 10px 0;
  font-size: 0.6rem;
}

.buttonLinkText {
  color: white;
}

.buttonLinkText:hover {
  text-decoration: none;
}

.content .myButton {
  padding: 0;
}

.content .myButton .MuiButton-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.content .myButton .MuiButton-label a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.MuiButtonBase-root.current {
  color: white;
}

.MuiButtonBase-root.midkniteeuro {
  border-color: #133D97;
}

.MuiButtonBase-root.midkniteeuro.current {
  background: #133D97;
}

.MuiButtonBase-root.midkniteusa {
  border-color: red;
}

.MuiButtonBase-root.midkniteusa.current {
  background: red;
}

.MuiButtonBase-root.midkniteasia {
  border-color: orange;
}

.MuiButtonBase-root.midkniteasia.current {
  background: orange;
}

.MuiButtonBase-root.midknitepacific {
  border-color: skyblue;
}

.MuiButtonBase-root.midknitepacific.current {
  background: skyblue;
}

.MuiButtonBase-root.midknitelatin {
  border-color: darkgreen;
}

.MuiButtonBase-root.midknitelatin.current {
  background: darkgreen;
}

.MuiButtonBase-root.midkniteafrica {
  border-color: brown;
}

.MuiButtonBase-root.midkniteafrica.current {
  background: brown;
}

.MuiButtonBase-root.midknitejapan {
  border-color: blue;
}

.MuiButtonBase-root.midknitejapan.current {
  background: blue;
}

.MuiButtonBase-root.midkniteblue {
  border-color: deepskyblue;
}

.MuiButtonBase-root.midkniteblue.current {
  background: deepskyblue;
}

.MuiButtonBase-root.bleudetokyo {
  border-color: #0000CC;
}

.MuiButtonBase-root.bleudetokyo.current {
  background: #0000CC;
}

.nowLoading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: calc(100vh - 80px);
}
