@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
/* schedule style */
.schedule .list li, .alltimeschedule .list li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.schedule .list .date, .alltimeschedule .list .date {
  margin: 0 10px 0 0;
}

@media only screen and (min-width: 961px) {
  .schedule .list .date, .alltimeschedule .list .date {
    width: 265px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
}

.schedule .list .tagBox, .alltimeschedule .list .tagBox {
  width: 90px;
  margin: 0 10px 0 0;
}

.schedule .list .tagBox .tag, .alltimeschedule .list .tagBox .tag {
  display: block;
  width: 90px;
  margin: 0;
}

/* tour style */
.tour .list.archive {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.tour .list.archive li {
  width: 48%;
  margin: 0 4% 20px 0;
}

@media only screen and (min-width: 961px) {
  .tour .list.archive li {
    width: 24%;
    margin: 0 1.3% 20px 0;
    display: block;
  }
}

.tour .list.archive li:nth-child(2n) {
  margin: 0 0 10px 0;
}

@media only screen and (min-width: 961px) {
  .tour .list.archive li:nth-child(2n) {
    margin: 0 1.3% 20px 0;
  }
}

@media only screen and (min-width: 961px) {
  .tour .list.archive li:nth-child(4n) {
    margin: 0 0 20px 0;
  }
}

.tour .list.archive li .date .tag {
  width: 90px;
  margin: 0;
}

.tour .list.archive.listStyle li {
  width: 100%;
  margin: 0 0 10px 0;
  display: block;
}

.tour .list.archive.listStyle li .thumbnail {
  display: none;
}

@media only screen and (min-width: 961px) {
  .tour .list.archive.listStyle li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .tour .list.archive.listStyle li:nth-child(2n) {
    margin: 0 0 10px 0;
  }
}

/* tour and schedule common style */
.tourCategory button {
  margin: 0 10px 10px 0;
}

.tourCategory button.current {
  background: darkgrey;
  color: white;
}

.tourCategorySmall button {
  margin: 0 10px 10px 0;
  text-transform: none;
}

.tourCategorySmall button.current {
  background: darkgrey;
  color: white;
}

.infoText {
  margin: 0 0 50px;
}

.country {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.country .countryName {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 10px 0 0;
}

.country .countryName img {
  width: 20px;
  margin: 0 5px 0 0;
}

.scheduleTable {
  margin: 0 0 50px;
}

.scheduleTable .countryCell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.scheduleFlag {
  width: 20px;
  margin: 0 5px 0 0;
}

.tourMap {
  margin: 20px 0 50px;
  height: 400px;
  width: 100%;
}

.worldmap .tourMap {
  height: 800px;
}

.markerArea {
  display: block;
  position: relative;
}

.markerArea .marker {
  font-size: 40px;
  position: absolute;
  top: -35px;
  left: -20px;
  color: darkblue;
}

.markerPopUp {
  position: absolute;
  top: -80px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  display: none;
  background: white;
  padding: 5px 10px;
  white-space: nowrap;
  z-index: 100;
}

.markerPopUp .markerPopUpContent {
  margin: 5px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.markerPopUp .markerPopUpContent img {
  width: 20px;
  margin: 0 5px 0 0;
}

.markerPopupClose {
  font-size: 17px;
  position: absolute;
  top: -12px;
  right: -12px;
}

.withIcon {
  position: relative;
}

.withIcon svg {
  position: absolute;
  top: -2px;
  font-size: 1.5rem;
}

.withIcon span {
  text-indent: 1.8em;
}

.slick-slide div {
  outline: none;
  border: none;
  border-style: none;
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: black;
}

@media only screen and (min-width: 961px) {
  .slick-prev:before, .slick-next:before {
    font-size: 30px;
  }
}

.slick-next {
  right: -20px;
  z-index: 1;
}

.slick-prev {
  left: -20px;
  z-index: 1;
}

@media only screen and (min-width: 961px) {
  .slick-prev {
    left: -30px;
  }
}

.slick-slide img {
  margin: 0 auto;
}

.nowLoading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: calc(100vh - 80px);
}
