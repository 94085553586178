@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
/* contents style */
.contents {
  width: 100%;
}

.contents .list.archive {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.contents .list.archive .listPart {
  color: #2c2c2c;
  position: relative;
  width: 48%;
  padding: 10px;
  margin: 0 4% 40px 0;
  display: inline-block;
  background: white;
  -webkit-box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 961px) {
  .contents .list.archive .listPart {
    padding: 20px;
    width: 24%;
    margin: 0 1.3% 50px 0;
  }
}

.contents .list.archive .listPart:hover {
  background: #2c2c2c;
  text-decoration: none;
}

.contents .list.archive .listPart:hover::after {
  width: 100%;
  height: 100%;
  border: 4px solid #2c2c2c;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.contents .list.archive .listPart:hover .titleBox {
  background: #2c2c2c;
  color: white;
}

.contents .list.archive .listPart li {
  display: block;
  margin: 0;
}

.contents .list.archive .listPart .tag {
  position: absolute;
  height: 30px;
  bottom: -30px;
  right: 0;
  padding: 5px 10px;
  text-align: center;
}

.contents .list.archive .listPart .tag.revolucion {
  padding: 5px;
}

@media only screen and (max-width: 320px) {
  .contents .list.archive .listPart .tag.revolucion {
    padding: 5px 4px;
    letter-spacing: -0.8px;
  }
}

@media only screen and (max-width: 320px) {
  .contents .list.archive .listPart .tag.goodbyeworld, .contents .list.archive .listPart .tag.trekkingworld {
    padding: 5px 2px;
  }
}

.contents .list.archive .listPart .thumbnail {
  line-height: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 100%;
}

.contents .list.archive .listPart .thumbnail img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
}

.contents .list.archive .listPart .titleBox {
  background: white;
  min-height: 50px;
  padding: 5px 10px;
}

.contents .list.archive .listPart .title {
  font-size: 20px;
}

.contents .list.archive .listPart:nth-child(2n) {
  margin: 0 0 40px 0;
}

@media only screen and (min-width: 961px) {
  .contents .list.archive .listPart:nth-child(2n) {
    margin: 0 1.3% 50px 0;
  }
}

@media only screen and (min-width: 961px) {
  .contents .list.archive .listPart:nth-child(4n) {
    margin: 0 0 50px 0;
  }
}

.contents .dateCreated {
  font-weight: bold;
  margin: 0 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 961px) {
  .contents .dateCreated {
    margin: 0 0 5px;
  }
}

.contents .dateCreated svg {
  font-size: 1rem;
  margin: 0 5px 0 0;
}

.contents .countryBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 10px;
}

.contents .countryName {
  margin: 0 10px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contents .countryName img {
  width: 25px;
  margin: 0 5px 0 0;
  height: auto;
}

.contents .mainVisual {
  margin: 0 0 40px;
  position: relative;
}

.contents .mainVisual img {
  max-width: 100%;
}

.contents .mainVisual .tagContainer {
  margin: 0;
  position: absolute;
  top: -15px;
  right: 0;
}

.contents .mainVisual .tagContainer .tag {
  margin: 0;
  padding: 0 20px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
}

.contents .mainHeading {
  font-family: 'Noto Serif JP', serif;
  margin: 0 0 50px;
  color: white;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

@media only screen and (min-width: 961px) {
  .contents .mainHeading {
    padding: 15px;
    font-size: 16px;
  }
}

.contents .gmap {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.contents .gmap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.gutereise h2 {
  border-left: 5px solid #FBDD63;
  padding: 0 0 0 10px;
}

.gutereise h3 {
  color: #fff;
  background: #EF511D;
  font-weight: bold;
  padding: 2px 5px;
}

.gutereise .flex {
  margin: 50px 0 0;
}

@media only screen and (min-width: 961px) {
  .gutereise .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.gutereise .souveniorBox {
  position: relative;
  border: 1px solid #FBDD63;
  margin: 20px 0 0;
  padding: 10px;
}

.gutereise .souveniorBox .flex {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

@media only screen and (min-width: 961px) {
  .gutereise .souveniorBox {
    margin: 0 0 0 20px;
  }
}

.gutereise .traveltips {
  margin: 20px 0 0;
  border: 2px dotted #000;
  padding: 20px;
}

.gutereise .pricelist {
  margin: 20px 0 0;
}

@media only screen and (min-width: 961px) {
  .gutereise .pricelist {
    margin: 0;
  }
}

.trekkingworld h2 {
  border-left: 5px solid #035B30;
  padding: 0 0 0 10px;
}

img {
  height: auto !important;
}
